<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form class="container">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Név</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Helyszín</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.location"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Mentés
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      formData: {
        name: null,
        location: null,
      },

      required,
    }
  },
  methods: {
    ...mapActions(['createWarehouse']),
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.createWarehouse(this.formData).then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeres mentés',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.replace({
              name: 'warehouses-index',
            })
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
